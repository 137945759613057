import React from "react";

const Info = () => {
  return (
    <>
       <div>Info Page</div>
    </>
  );
};

export default Info;